import { isRelease } from '$lib/utils';
import * as Sentry from '@sentry/sveltekit';
import type { HandleClientError } from '@sveltejs/kit';

if (isRelease()) {
	Sentry.init({
		dsn: 'https://40c78da9d3aa00b94ec04ea9d6eb882c@o4504899687874560.ingest.sentry.io/4505692867330048',
		sampleRate: 1.0,
		replaysSessionSampleRate: 0.0,
		replaysOnErrorSampleRate: 1.0,
		release: process.env.SENTRY_RELEASE,
		environment: import.meta.env.MODE,
		integrations: [
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: true
			})
		],
		tracesSampler: (samplingContext) => {
			if (
				samplingContext.location?.pathname === '/' ||
				samplingContext.location?.pathname === '/login' ||
				samplingContext.location?.pathname === '/register'
			) {
				return 0;
			} else {
				return 1;
			}
		}
	});
}

const _handleError: HandleClientError = ({ error, event }) => {
	const errorId = crypto.randomUUID();

	if (isRelease()) {
		Sentry.captureException(error, { extra: { event, errorId } });
	} else {
		console.error(error);
	}
	return {
		message: "An unexpected error occurred. We're working on it.",
		errorId
	};
};

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = isRelease() ? Sentry.handleErrorWithSentry() : _handleError;
